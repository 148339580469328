import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility.js';
import BankModeMappingDetails from './modeMappingDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'BankModeMapping',
  components: {
    ModelSelect,
    BankModeMappingDetails
  },
  watch: {
    currentPage: function() {
      this.getBankMethodDetail();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBankMethodDetail();
    }
  },
  data() {
    return {
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      loader: false,
      editMode: false,
      module: { value: null, text: null },
      legalEntity: { value: null, text: null },
      project: { value: null, text: null },
      bank: { value: null, text: null },
      bankAccount: { value: null, text: null },
      methodType: { value: null, text: null },
      paymentMode: { value: null, text: null },
      unsubscribe: null,
      bankRowDetails: null,
      showSelectedBankDetails: false,
      showValueSetModal: false,
      showModal: false,
      payload: {},
      parent_value_set_id: null,
      selectedBankAccount: null,
      selectedBankAccountId: null,
      methodNameList: [
        {
          value: null,
          text: null
        }
      ],
      selectedMethod: {
        value: null,
        text: null
      },
      bankModeData: [],
      bankModeFields: [
        {
          key: 'bank_name'
        },
        {
          key: 'branch_name'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'account_name'
        },
        {
          key: 'account_num'
        },
        {
          key: 'method_type_meaning',
          label: 'Method Type'
        },
        {
          key: 'party_method_name',
          label: 'Methods Name'
        },
        {
          key: 'project_name'
        },
        {
          key: 'receipt_mode_vset_meaning',
          label: 'Receipt Mode'
        },
        {
          key: 'default_method'
        },
        {
          key: 'auto_clearing'
        },
        {
          key: 'auto_apply'
        },
        {
          key: 'active'
        },
        {
          key: 'h2h_enabled',
          label: 'H2H Enabled'
        }
      ],
      depVsetParam: null,
      showBankModeModal: false
    };
  },
  validations: {
    module: { text: { required } }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideBankModeModal(true);
          this.bankRowDetails = null;
        }
        if (actionName === 'download' && !this.showBankModeModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'bankMpngMode/getBankMethodDetail',
            'bankmodemapping',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    showHideBankModeModal(flag) {
      this.showBankModeModal = flag;
    },
    getBankMethodDetail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_id: this.module.value,
          le_id: this.legalEntity.value,
          project_id: this.project.value,
          bank_account_id: this.bankAccount.value,
          method_type: this.methodType.value,
          method_name: this.selectedMethod.text,
          receipt_mode_vset_code: this.paymentMode.value
        };
        this.loader = true;
        this.$store
          .dispatch('bankMpngMode/getBankMethodDetail', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.bankModeData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getReceiptMethods() {
      const payload = {
        module_id: this.module.value,
        le_id: this.legalEntity.value,
        project_id: this.project.value,
        bank_account_id: this.bankAccount.value
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getReceiptMethodsDetail', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          const receiptMethodArr = results.map(type => {
            return {
              value: type.party_receipt_method_id,
              text: type.receipt_methods_name
            };
          });
          this.methodNameList = receiptMethodArr;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPaymentMethods() {
      const payload = {
        module_id: this.module.value,
        le_id: this.legalEntity.value,
        project_id: this.project.value,
        bank_account_id: this.bankAccount.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          const paymentMethodArr = results.map(type => {
            return {
              value: type.party_payment_method_id,
              text: type.payment_methods_name
            };
          });
          this.methodNameList = paymentMethodArr;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    isBankAvailable() {
      if (this.legalEntity.value && this.bank.value) {
        this.openValueSetModal('BANK_MODE_ACCOUNT');
      } else {
        alert('Please Select Legal Entity and Bank');
      }
    },
    rowSelected(rowData) {
      this.showHideBankModeModal(true);
      this.bankRowDetails = rowData;
      setTimeout(() => {
        this.eventBus.$emit('selectedRowDetails', rowData), 0;
      });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_MODE_ACCOUNT) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.bank.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.parent_value_set_id = 1;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.module.text = item.value_code;
        this.module.value = item.value_set_dtl_id;
        this.project.text = null;
        this.project.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_MODE_LIST) {
        this.bank.text = item.value_code;
        this.bank.value = item.value_set_dtl_id;
        this.bankAccount.text = null;
        this.bankAccount.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_MODE_ACCOUNT) {
        this.bankAccount.text = item.value_code;
        this.bankAccount.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PARTY_METHOD_TYPE) {
        this.methodType.text = item.value_meaning;
        this.methodType.value = item.value_code;
        if (this.methodType.value === 'RECEIPT') {
          this.getReceiptMethods();
        } else if (this.methodType.value === 'PAYMENT') {
          this.getPaymentMethods();
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode.text = item.value_meaning;
        this.paymentMode.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openModuleBased(moduleName) {
      if (!this.module.value || !this.legalEntity.value) {
        return alert('Please Select Module and Legal Entity');
      } else if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    clearFilters() {
      this.module = { value: null, text: null };
      this.legalEntity = { value: null, text: null };
      this.project = { value: null, text: null };
      this.bank = { value: null, text: null };
      this.bankAccount = { value: null, text: null };
      this.methodType = { value: null, text: null };
      this.selectedMethod = { value: null, text: null };
      this.paymentMode = { value: null, text: null };
      this.bankModeData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.module.text = null;
        this.module.value = null;
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'Fms' || vsetCode === 'Lease Management') {
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.BANK_MODE_LIST) {
        this.bank.text = null;
        this.bank.value = null;
        this.bankAccount.text = null;
        this.bankAccount.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.BANK_MODE_ACCOUNT) {
        this.bankAccount.text = null;
        this.bankAccount.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.PARTY_METHOD_TYPE) {
        this.methodType.text = null;
        this.methodType.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode.text = null;
        this.paymentMode.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
