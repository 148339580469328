import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility.js';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'BankModeMappingDetails',
  components: {
    ModelSelect,
    appStrings
  },
  props: ['bankRowDetails'],
  watch: {
    selectedMethod: function() {
      if (
        this.selectedMethod.value !== null &&
        this.legalEntity.value !== null
      ) {
        this.getPartyBankDetails();
      }
    }
  },
  data() {
    return {
      showSelectedBankDetails: false,
      editMode: false,
      showValueSetModal: false,
      showModal: false,
      tab: null,
      disableFields: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      parent_value_set_id: null,
      responseMsg: '',
      selectedMethodType: {
        value: null,
        text: null
      },
      methodNameList: [
        {
          value: null,
          text: null
        }
      ],
      selectedMethod: {
        value: null,
        text: null
      },
      recieptMethodMpngId: null,
      module: { value: null, text: null },
      legalEntity: { value: null, text: null },
      project: { value: null, text: null },
      bank: { value: null, text: null },
      methodType: { value: null, text: null },
      paymentMode: { value: null, text: null },
      bankName: null,
      branchName: null,
      accountName: null,
      accountNum: null,
      defaultMethod: false,
      autoClearing: false,
      autoApply: false,
      active: false,
      h2hEnabled: false
    };
  },
  validations: {
    module: {
      text: { required }
    },
    legalEntity: {
      text: { required }
    },
    project: {
      text: { required }
    },
    paymentMode: {
      text: { required }
    },
    methodType: {
      text: { required }
    }
  },
  mounted() {
    if (this.bankRowDetails) {
      this.recieptMethodMpngId = this.bankRowDetails.ar_proj_receipt_method_mpng_id;
      this.getBankMethodDetailById(this.recieptMethodMpngId);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditBankMode();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.recieptMethodMpngId
          });
        }
      }
    });
  },
  methods: {
    getReceiptMethods() {
      const payload = {
        module_id: this.module.value,
        le_id: this.legalEntity.value,
        project_id: this.project.value
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getReceiptMethodsDetail', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          const receiptMethodArr = results.map(type => {
            return {
              value: type.party_receipt_method_id,
              text: type.receipt_methods_name
            };
          });
          this.methodNameList = receiptMethodArr;
          this.selectedMethod =
            receiptMethodArr && receiptMethodArr.length == 1
              ? receiptMethodArr[0]
              : this.selectedMethod;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPaymentMethods() {
      const payload = {
        module_id: this.module.value,
        le_id: this.legalEntity.value,
        project_id: this.project.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          const paymentMethodArr = results.map(type => {
            return {
              value: type.party_payment_method_id,
              text: type.payment_methods_name
            };
          });
          this.methodNameList = paymentMethodArr;
          this.selectedMethod =
            paymentMethodArr && paymentMethodArr.length == 1
              ? paymentMethodArr[0]
              : this.selectedMethod;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openModuleBased(moduleName) {
      if (!this.module.value || !this.legalEntity.value) {
        return alert('Please Select Module and Legal Entity');
      } else if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.module.text = item.value_code;
        this.module.value = item.value_set_dtl_id;
        this.project.text = null;
        this.project.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PARTY_METHOD_TYPE) {
        this.methodType.text = item.value_meaning;
        this.methodType.value = item.value_code;
        if (this.methodType.value === 'RECEIPT') {
          this.getReceiptMethods();
        } else if (this.methodType.value === 'PAYMENT') {
          this.getPaymentMethods();
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode.text = item.value_meaning;
        this.paymentMode.value = item.value_code;
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.parent_value_set_id = 1;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getPartyBankDetails() {
      const payload = {
        le_id: this.legalEntity.value,
        party_method_id: this.selectedMethod.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPartyBankDetails', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.bankName = results.party_name;
          this.branchName = results.branch_name;
          this.accountName = results.account_name;
          this.accountNum = results.account_num;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBankMethodDetailById(ar_proj_receipt_method_mpng_id) {
      this.loader = true;
      this.$store
        .dispatch(
          'bankMpngMode/getBankMethodDetailById',
          ar_proj_receipt_method_mpng_id
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.recieptMethodMpngId = results.ar_proj_receipt_method_mpng_id;
            this.module.text = results.module_name;
            this.module.value = results.module_id;
            this.legalEntity.text = results.le_name;
            this.legalEntity.value = results.le_id;
            this.project.text = results.project_name;
            this.project.value = results.project_id;
            this.methodType.text = results.method_type_meaning;
            this.methodType.value = results.method_type;
            this.selectedMethod.text = results.party_method_name;
            this.selectedMethod.value = results.party_method_id;
            this.paymentMode.text = results.receipt_mode_vset_meaning;
            this.paymentMode.value = results.receipt_mode_vset_code;
            this.bankName = results.bank_name;
            this.branchName = results.branch_name;
            this.accountName = results.account_name;
            this.accountNum = results.account_num;
            this.defaultMethod = results.default_method;
            this.autoClearing = results.auto_clearing;
            this.autoApply = results.auto_apply;
            this.active = results.active;
            this.h2hEnabled = results.h2h_enabled;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditBankMode() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          receipt_methods_prj_mpng_id: this.recieptMethodMpngId
            ? this.recieptMethodMpngId
            : 0,
          module_id: this.module.value,
          entity_id: this.project.value,
          method_type: this.methodType.value,
          party_method_id: this.selectedMethod.value,
          receipt_mode_vset_code: this.paymentMode.value,
          default_method: this.defaultMethod,
          auto_clearing: this.autoClearing,
          active: this.active,
          h2h_enabled: this.h2hEnabled,
          auto_apply: this.autoApply
        };
        this.loader = true;
        this.$store
          .dispatch('bankMpngMode/addEditBankMode', payload)
          .then(response => {
            this.$emit('updateBankList');
            this.showAlert = true;
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.recieptMethodMpngId =
                response.data.data.ar_proj_receipt_method_mpng_id;
              this.getBankMethodDetailById(this.recieptMethodMpngId);
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
        this.module.text = null;
        this.module.value = null;
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'Fms' || vsetCode === 'Lease Management') {
        this.project.text = null;
        this.project.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.PARTY_METHOD_TYPE) {
        this.methodType.text = null;
        this.methodType.value = null;
        this.bankName = null;
        this.branchName = null;
        this.accountName = null;
        this.accountNum = null;
        this.methodNameList = [{ value: null, text: null }];
      } else if (vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode.text = null;
        this.paymentMode.value = null;
      }
    }
  }
};
